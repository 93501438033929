import { gql } from '@apollo/client';

const LOGIN = gql`
  mutation resolveAuth($authData: AuthInput!) {
    resolveAuth(authData: $authData) {
      success
      message
      token
    }
  }
`;

const TEST_LOGIN = gql`
  mutation TestLogin($email: String!) {
    testLogin(email: $email) {
      success
      token
    }
  }
`;

const SAVE_MERGED_DEALS = gql`
  mutation SaveMergedDeals($mergedDeals: [OutputDealsInput!]) {
    saveOutputDeals(input: $mergedDeals) {
      success
      message
    }
  }
`;

const DELETE_MERGED_DEALS = gql`
  mutation DeleteDeals($deleteMergedDealsInput: [DeleteMergedDealsInput!]) {
    deleteDeals(input: $deleteMergedDealsInput) {
      success
      message
    }
  }
`;

const SAVE_MERGED_COMPANIES = gql`
  mutation MergeCompanies($mergedCompanies: MergeCompaniesInput!) {
    mergeCompanies(input: $mergedCompanies) {
      success
      message
    }
  }
`;

const SAVE_MATCHED_COMPANIES = gql`
  mutation MatchCompanies($matchCompanies: MatchCompaniesInput!) {
    matchCompanies(input: $matchCompanies) {
      success
      message
    }
  }
`;

const SAVE_MATCHED_ISSUER = gql`
  mutation MatchIssuer($matchIssuerInput: MatchIssuerInput!) {
    matchIssuer(input: $matchIssuerInput) {
      success
      message
    }
  }
`;

const DELETE_MERGED_COMPANIES = gql`
  mutation DeleteMergedCompanies(
    $team: Team!
    $companies: [DeleteMergedCompaniesInput!]
  ) {
    deleteMergedCompanies(team: $team, companies: $companies) {
      success
      message
    }
  }
`;

const DELETE_MATCHED_COMPANIES = gql`
  mutation DeleteMatchedCompanies(
    $matchToDelete: DeleteMatchedCompaniesInput!
  ) {
    deleteMatchedCompanies(input: $matchToDelete) {
      success
      message
    }
  }
`;

const DELETE_MANUALLY_MATCHED_ISSUER = gql`
  mutation DeleteManuallyMatchedIssuer(
    $matchToDelete: DeleteManuallyMatchedIssuerInput
  ) {
    deleteManuallyMatchedIssuer(input: $matchToDelete) {
      success
      message
    }
  }
`;

const SAVE_MATCHED_AFFINITY = gql`
  mutation MatchAffinity($matchAffinityInput: MatchAffinityInput) {
    matchAffinity(input: $matchAffinityInput) {
      success
      message
    }
  }
`;

const DELETE_MATCHED_AFFINITY = gql`
  mutation DeleteManuallyMatchedAffinity(
    $matchToDelete: DeleteManuallyMatchedAffinityInput
  ) {
    deleteManuallyMatchedAffinity(input: $matchToDelete) {
      success
      message
    }
  }
`;

const SAVE_MATCHED_NS_COMPANY = gql`
  mutation MatchNetsuiteCompany(
    $matchNetsuiteCompanyInput: MatchNetsuiteCompanyInput!
  ) {
    matchNetsuiteCompany(input: $matchNetsuiteCompanyInput) {
      success
      message
    }
  }
`;

const DELETE_MATCHED_NS_COMPANY = gql`
  mutation DeleteManuallyMatchedNsCompany(
    $matchToDelete: DeleteManuallyMatchedNsCompanyInput
  ) {
    deleteManuallyMatchedNsCompany(input: $matchToDelete) {
      success
      message
    }
  }
`;

const SAVE_MATCHED_NS_FUND = gql`
  mutation MatchNetsuiteFud($matchNetsuiteFundInput: MatchNetsuiteFundInput!) {
    matchNetsuiteFund(input: $matchNetsuiteFundInput) {
      success
      message
    }
  }
`;

const DELETE_MATCHED_NS_FUND = gql`
  mutation DeleteManuallyMatchedNsFund(
    $matchToDelete: DeleteManuallyMatchedNsFundInput
  ) {
    deleteManuallyMatchedNsFund(input: $matchToDelete) {
      success
      message
    }
  }
`;

const SAVE_TAX_TRANSACTION_OVERRIDE = gql`
  mutation SaveTaxTransactionOverride($input: [SaveTaxTransactionInput!]) {
    saveTaxTransactionOverride(input: $input) {
      success
      message
    }
  }
`;

const SAVE_TAX_DEAL_OVERRIDE = gql`
  mutation saveTaxDealOverride($input: [SaveTaxDealOverrideInput!]!) {
    saveTaxDealOverride(input: $input) {
      success
      message
    }
  }
`;

const SAVE_VALUATION_COMPANY_CONCLUSION = gql`
  mutation SaveValuationCompanyConclusion(
    $input: SaveValuationCompanyConclusionInput!
  ) {
    saveValuationCompanyConclusion(input: $input) {
      success
      message
    }
  }
`;

const SAVE_VALUATION_COMPANY_UPDATES = gql`
  mutation SaveValuationCompanyUpdates(
    $input: SaveValuationCompanyUpdatesInput!
  ) {
    saveValuationCompanyUpdates(input: $input) {
      success
      message
    }
  }
`;

const MERGE_NETSUITE_FUNDS = gql`
  mutation MergeNetsuiteFunds($primary_id: String!, $secondary_ids: [String!]) {
    mergeNetsuiteFunds(
      input: {
        primary_fund_id: $primary_id
        secondary_fund_ids: $secondary_ids
      }
    ) {
      success
    }
  }
`;

const MERGE_NETSUITE_COMPANIES = gql`
  mutation MergeNetsuiteCompanies(
    $primary_id: String!
    $secondary_ids: [String!]
  ) {
    mergeNetsuiteCompanies(
      input: {
        primary_company_id: $primary_id
        secondary_company_ids: $secondary_ids
      }
    ) {
      success
    }
  }
`;

const SAVE_FUND_OVERVIEW_COMPANIES_RENAMING = gql`
  mutation SaveFundOverviewCompaniesRenaming(
    $input: [SaveFundOverviewCompanyRenamingItem!]
  ) {
    saveFundOverviewCompaniesRenaming(input: $input) {
      success
      message
    }
  }
`;

const DELETE_FUND_OVERVIEW_COMPANIES_RENAMING = gql`
  mutation DeleteFundOverviewCompaniesRenaming(
    $input: [DeleteFundOverviewCompanyRenamingItem]
  ) {
    deleteFundOverviewCompaniesRenaming(input: $input) {
      ...OperationResult
    }
  }
`;

const BLOCKER_MANAGEMENT_SAVE_OVERRIDES = gql`
  mutation BlockerManagementSaveOverrides(
    $inputData: [BlockerManagementSaveOverrideItemInput!]!
  ) {
    blockerManagementSaveOverrides(inputData: $inputData) {
      ...OperationResult
    }
  }
`;

const BLOCKER_MANAGEMENT_DELETE_OVERRIDES = gql`
  mutation BlockerManagementDeleteOverrides(
    $inputData: [BlockerManagementDeleteOverrideItemInput!]!
  ) {
    blockerManagementDeleteOverrides(inputData: $inputData) {
      ...OperationResult
    }
  }
`;

const SAVE_LP_PROFILE_CONTACTS = gql`
  mutation LpProfileSaveContacts($inputData: [LpProfileSaveContactsInput!]!) {
    lpProfileSaveContacts(inputData: $inputData) {
      ...OperationResult
    }
  }
`;

const SAVE_IR_PROFILE_NOTES = gql`
  mutation LpProfileSaveNotes($inputData: [LpProfileSaveNotesInput!]!) {
    lpProfileSaveNotes(inputData: $inputData) {
      ...OperationResult
    }
  }
`;

const LP_PROFILE_PDF_REPORT = gql`
  mutation LpProfilePdfReport($inputData: LpProfileReportInput!) {
    lpProfilePdfReport(inputData: $inputData) {
      ...TaskOperationResultAllFields
    }
  }
`;

export const FUND_OVERVIEW_PDF_REPORT_MUTATION_DOCUMENT = gql`
  mutation FundOverviewPdfReport($inputData: FundOverviewReportInput!) {
    fundOverviewPdfReport(inputData: $inputData) {
      ...TaskOperationResultAllFields
    }
  }
`;

export const INVESTMENT_LISTING_EXCEL_REPORT_MUTATION_DOCUMENT = gql`
  mutation InvestmentListingExcelReport($inputData: FundOverviewReportInput!) {
    investmentListingExcelReport(inputData: $inputData) {
      ...TaskOperationResultAllFields
    }
  }
`;

export const MANDATE_TRACKER_CREATE_COMPANY_MUTATION_DOCUMENT = gql`
  mutation MandateTrackerCreateCompany(
    $inputData: MandateTrackerCreateCompanyInput!
  ) {
    mandateTrackerCreateCompany(inputData: $inputData) {
      ...OperationResult
    }
  }
`;

export const MANDATE_TRACKER_CREATE_OPPORTUNITY_MUTATION_DOCUMENT = gql`
  mutation MandateTrackerCreateOpportunity(
    $inputData: MandateTrackerCreateOpportunityInput!
  ) {
    mandateTrackerCreateOpportunity(inputData: $inputData) {
      ...OperationResult
    }
  }
`;

export const MONTHLY_CASH_BALANCE_EXCEL_REPORT = gql`
  mutation MonthlyCashBalanceExcelReport(
    $inputData: MonthlyBalancePlanningReportInput!
  ) {
    monthlyCashBalanceExcelReport(inputData: $inputData) {
      ...TaskOperationResultAllFields
    }
  }
`;

export const PENDING_INVESTMENTS_SAVE_CASH_BALANCE_MUTATION_DOCUMENT = gql`
  mutation PendingInvestmentsSaveEntity(
    $inputData: PendingInvestmentsSaveCashInput!
  ) {
    pendingInvestmentsSaveCashBalance(inputData: $inputData) {
      ...OperationResult
    }
  }
`;

export const PENDING_INVESTMENTS_SAVE_ENTITY_MUTATION_DOCUMENT = gql`
  mutation PendingInvestmentsSaveEntity(
    $inputData: [PendingInvestmentsSaveEntityInput!]!
  ) {
    pendingInvestmentsSaveEntity(inputData: $inputData) {
      ...OperationResult
    }
  }
`;

export const PENDING_INVESTMENTS_SAVE_ENTITY_FUNDED_FROM_MUTATION_DOCUMENT = gql`
  mutation PendingInvestmentsSaveEntity(
    $inputData: [PendingInvestmentsSaveFundedFromInput!]!
  ) {
    pendingInvestmentsSaveEntityFundedFrom(inputData: $inputData) {
      ...OperationResult
    }
  }
`;

export const SWEEP_BALANCE_EXCEL_REPORT_MUTATION_DOCUMENT = gql`
  mutation SweepBalanceExcelReport($inputData: SweepBalanceReportInput!) {
    sweepBalanceExcelReport(inputData: $inputData) {
      ...TaskOperationResultAllFields
    }
  }
`;

const COINVEST_CUSTOM_FIELDS = gql`
  mutation SaveLpCoinvestCustomFields($input: SaveLpCoinvestCustomFieldsInput) {
    saveLpCoinvestCustomFields(input: $input) {
      ...OperationResult
    }
  }
`;

const SAVE_FORM_VALUES = gql`
  mutation SaveValues($inputData: PortfolioManagementSaveValuesInput!) {
    portfolioManagementSaveInputValues(inputData: $inputData) {
      message
      success
    }
  }
`;

const DELETE_COMPANY_VALUES = gql`
  mutation DeleteValues($inputData: PortfolioManagementDeleteValuesInput!) {
    portfolioManagementDeleteInputValues(inputData: $inputData) {
      message
      success
    }
  }
`;

const SYNC_LEAVING_EMPLOYEES = gql`
  mutation hrSyncLeavingSoon($inputData: HRSyncEmployees!) {
    hrSyncLeavingSoon(syncStateInput: $inputData) {
      message
      success
    }
  }
`;

const SYNC_NEWCOMERS_EMPLOYEES = gql`
  mutation hrSyncComingSoon($inputData: HRSyncEmployees!) {
    hrSyncComingSoon(syncStateInput: $inputData) {
      message
      success
    }
  }
`;

const SAVE_LP_COINVEST_FUNDS_FIELDS = gql`
  mutation SaveLpCoinvestFundsFields($input: SaveLpCoinvestFundsFieldsInput!) {
    saveLpCoinvestFundsFields(input: $input) {
      ...OperationResult
    }
  }
`;

const DELETE_LP_COINVEST_FUNDS_FIELDS = gql`
  mutation DeleteLpCoinvestFundsFields(
    $input: DeleteLpCoinvestFundsFieldsInput!
  ) {
    deleteLpCoinvestFundsFields(input: $input) {
      ...OperationResult
    }
  }
`;

const SAVE_TAX_DEAL_LOT_CHANGES = gql`
  mutation saveOverrides($inputData: [SaveTaxDealLotOverrideInput!]!) {
    taxDealLotSaveOverrides(inputData: $inputData) {
      message
      success
    }
  }
`;

const matchCompaniesSfAffinityMatchCompanies = gql`
  mutation MatchCompaniesSfAffinityMatchCompanies(
    $inputData: [MatchCompaniesSfAffinityMatchDeleteInput!]!
  ) {
    matchCompaniesSfAffinityMatchCompanies(inputData: $inputData) {
      ...OperationResult
    }
  }
`;

const matchCompaniesSfAllvueMatchCompanies = gql`
  mutation MatchCompaniesSfAllvueMatchCompanies(
    $inputData: [MatchCompaniesSfAllvueMatchDeleteInput!]!
  ) {
    matchCompaniesSfAllvueMatchCompanies(inputData: $inputData) {
      ...OperationResult
    }
  }
`;

const matchCompaniesSfAumniMatchCompanies = gql`
  mutation MatchCompaniesSfAumniMatchCompanies(
    $inputData: [MatchCompaniesSfAumniMatchDeleteInput!]!
  ) {
    matchCompaniesSfAumniMatchCompanies(inputData: $inputData) {
      ...OperationResult
    }
  }
`;

const matchCompaniesSfNetsuiteMatchCompanies = gql`
  mutation MatchCompaniesSfNetsuiteMatchCompanies(
    $inputData: [MatchCompaniesSfNetsuiteMatchDeleteInput!]!
  ) {
    matchCompaniesSfNetsuiteMatchCompanies(inputData: $inputData) {
      ...OperationResult
    }
  }
`;

const matchCompaniesSfAffinityDeleteMatchedCompanies = gql`
  mutation MatchCompaniesSfAffinityDeleteMatchedCompanies(
    $inputData: [MatchCompaniesSfAffinityMatchDeleteInput!]!
  ) {
    matchCompaniesSfAffinityDeleteMatchedCompanies(inputData: $inputData) {
      ...OperationResult
    }
  }
`;

const matchCompaniesSfAllvueDeleteMatchedCompanies = gql`
  mutation MatchCompaniesSfAllvueDeleteMatchedCompanies(
    $inputData: [MatchCompaniesSfAllvueMatchDeleteInput!]!
  ) {
    matchCompaniesSfAllvueDeleteMatchedCompanies(inputData: $inputData) {
      ...OperationResult
    }
  }
`;

const matchCompaniesSfAumniDeleteMatchedCompanies = gql`
  mutation MatchCompaniesSfAumniDeleteMatchedCompanies(
    $inputData: [MatchCompaniesSfAumniMatchDeleteInput!]!
  ) {
    matchCompaniesSfAumniDeleteMatchedCompanies(inputData: $inputData) {
      ...OperationResult
    }
  }
`;

const matchCompaniesSfNetsuiteDeleteMatchedCompanies = gql`
  mutation MatchCompaniesSfNetsuiteDeleteMatchedCompanies(
    $inputData: [MatchCompaniesSfNetsuiteMatchDeleteInput!]!
  ) {
    matchCompaniesSfNetsuiteDeleteMatchedCompanies(inputData: $inputData) {
      ...OperationResult
    }
  }
`;

const downloadTaxDealLotExcelReport = gql`
  mutation TaxDealLotExcelReport($inputData: TaxDealLotInput!) {
    taxDealLotExcelReport(inputData: $inputData) {
      message
      success
      taskId
    }
  }
`;

const matchLegalEntitiesSfAllvueFirmsMatch = gql`
  mutation matchLegalEntitiesSfAllvueFirmsMatch(
    $inputData: [MatchLegalEntitiesSfAllvueFirmsMatchDeleteInput!]!
  ) {
    matchLegalEntitiesSfAllvueFirmsMatch(inputData: $inputData) {
      ...OperationResult
    }
  }
`;

const matchLegalEntitiesSfAllvueFirmsDeleteMatched = gql`
  mutation matchLegalEntitiesSfAllvueFirmsDeleteMatched(
    $inputData: [MatchLegalEntitiesSfAllvueFirmsMatchDeleteInput!]!
  ) {
    matchLegalEntitiesSfAllvueFirmsDeleteMatched(inputData: $inputData) {
      ...OperationResult
    }
  }
`;

const hrApplyGeneratedEmployeeNumbers = gql`
  mutation hrApplyGeneratedEmployeeNumbers(
    $inputData: [HREmployeeNumberInput!]!
  ) {
    hrApplyGeneratedEmployeeNumbers(inputData: $inputData) {
      ...OperationResult
    }
  }
`;

const gqlMutations = {
  LOGIN,
  TEST_LOGIN,
  SAVE_MERGED_DEALS,
  DELETE_MERGED_DEALS,
  SAVE_MERGED_COMPANIES,
  DELETE_MERGED_COMPANIES,
  SAVE_MATCHED_COMPANIES,
  DELETE_MATCHED_COMPANIES,
  SAVE_MATCHED_ISSUER,
  DELETE_MANUALLY_MATCHED_ISSUER,
  SAVE_MATCHED_AFFINITY,
  DELETE_MATCHED_AFFINITY,
  SAVE_MATCHED_NS_COMPANY,
  DELETE_MATCHED_NS_COMPANY,
  SAVE_MATCHED_NS_FUND,
  DELETE_MATCHED_NS_FUND,
  SAVE_TAX_TRANSACTION_OVERRIDE,
  SAVE_TAX_DEAL_OVERRIDE,
  SAVE_VALUATION_COMPANY_CONCLUSION,
  SAVE_VALUATION_COMPANY_UPDATES,
  MERGE_NETSUITE_FUNDS,
  MERGE_NETSUITE_COMPANIES,
  SAVE_FUND_OVERVIEW_COMPANIES_RENAMING,
  DELETE_FUND_OVERVIEW_COMPANIES_RENAMING,
  BLOCKER_MANAGEMENT_SAVE_OVERRIDES,
  BLOCKER_MANAGEMENT_DELETE_OVERRIDES,
  SAVE_LP_PROFILE_CONTACTS,
  SAVE_IR_PROFILE_NOTES,
  LP_PROFILE_PDF_REPORT,
  COINVEST_CUSTOM_FIELDS,
  SAVE_FORM_VALUES,
  DELETE_COMPANY_VALUES,
  SYNC_LEAVING_EMPLOYEES,
  SAVE_LP_COINVEST_FUNDS_FIELDS,
  DELETE_LP_COINVEST_FUNDS_FIELDS,
  SYNC_NEWCOMERS_EMPLOYEES,
  SAVE_TAX_DEAL_LOT_CHANGES,
  matchCompaniesSfAffinityMatchCompanies,
  matchCompaniesSfAllvueMatchCompanies,
  matchCompaniesSfAumniMatchCompanies,
  matchCompaniesSfNetsuiteMatchCompanies,
  matchCompaniesSfAffinityDeleteMatchedCompanies,
  matchCompaniesSfAllvueDeleteMatchedCompanies,
  matchCompaniesSfAumniDeleteMatchedCompanies,
  matchCompaniesSfNetsuiteDeleteMatchedCompanies,
  downloadTaxDealLotExcelReport,
  matchLegalEntitiesSfAllvueFirmsMatch,
  matchLegalEntitiesSfAllvueFirmsDeleteMatched,
  hrApplyGeneratedEmployeeNumbers,
};

export default gqlMutations;
